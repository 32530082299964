/**
 * Adds functionality for video modal
 */
const closeVideo = (modal: HTMLElement, iframe: HTMLIFrameElement) => {
  if (modal.classList.contains('modal--active')) {
    iframe.src = ''
    modal.classList.remove('modal--active')
  }

  document.documentElement.classList.remove('overflow')
}

const videoModal = () => {
  const movies = document.querySelectorAll('.js-video-click')
  const modal = document.querySelector('.modal')
  const title = document.querySelector('.js-title')
  const videoIframe = document.querySelector('.js-video')
  const close = document.querySelector('.js-close')

  if(movies.length > 0 && modal && videoIframe && title && close) {
    for (let index = 0; index < movies.length; index++) {
      movies[index].addEventListener('click', () => {
        modal.classList.add('modal--active');
        (videoIframe as HTMLIFrameElement).src = 'https://player.vimeo.com/video/' + movies[index].getAttribute('data-src') + '?color=ef2200&byline=0&portrait=0';
        title.innerHTML = ''
        title.innerHTML += movies[index].getAttribute('data-title')
        document.documentElement.classList.add('overflow')
      })
    }

    close.addEventListener('click', () => {
      closeVideo(modal as HTMLElement, videoIframe as HTMLIFrameElement)
    });
  }
}

export default videoModal