import navigationToggle from './components/navigationToggle'
import videoModal from './components/videoModal'

document.addEventListener(
  'DOMContentLoaded',
  () => {
    navigationToggle()
    videoModal()
  },
  false
)
